import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApiInterceptor } from './shared/interceptors/interceptor';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideClientHydration(withHttpTransferCacheOptions({ includePostRequests: true, })),
    provideAnimations(),
  ],
};
